import React from "react";
import {Link} from 'gatsby';
import {Col} from 'react-grid-system';

import Layout from "../layouts";
import SEO from "../components/Seo";

import Section from "../components/Section";

const Page = () => (
  <Layout>
    <SEO title="404: Not found"/>
    <Section>
      <Col className="text-center">
        <h1 style={{fontSize: '96px'}}>404</h1>
        <h2>Sorry we couldn't find that page</h2>
        <Link to="/"><strong style={{fontSize: '24px'}}>Go home</strong></Link>
      </Col>
    </Section>
  </Layout>
);

export default Page
